<template>
	<div class="grid-content bg-purple userMain hHun">


		<el-row justify="space-between">

			<el-col :span="2">
				<div class="tabName">格式转换</div>
			</el-col>

			<el-row :span="12">
				<el-col :span="6" :gutter="10"><el-button type="primary" plain
						@click="dialog = true">上传文件</el-button></el-col>
				<el-col :span="7"><el-button type="primary" @click="vueSendMsg()">上传图片并识别</el-button></el-col>
<!--				<el-col :span="6"><el-button type="primary"-->
<!--						@click="getButtonState('edit')">CAD文件转换PNG</el-button></el-col>-->
				<el-col :span="8">
					<el-input v-model="searchcontent" style="  left: 20px;" maxlength="56" placeholder="搜索" clearable>
						<template #append>
							<el-button type="primary" @click="search">
								<span style="  display: flex;">
									<el-icon>
										<Search />
									</el-icon>
								</span>
							</el-button>
						</template>
					</el-input>
				</el-col>
			</el-row>

		</el-row>


		<el-scrollbar max-height="100%" class="filebox">
			<el-space wrap fill="true" alignment="normal">
				<div class="img-list">
					<div class="img-li-box" v-for="(item, key) in imgList" :key="key"
						@contextmenu.prevent="disableContextMenu">

						<img class="img-li-b--url" :src="item.url">


						<!-- 放大icon -->

						<div class="img-li-b--layer" style="align-content: center;">

							<el-space direction="vertical">
								<!-- 判断filetype 是否等于jpg或png -->
								<v-if :key="item.filetype" v-show="item.filetype == 'jpg' || item.filetype == 'png'">
									<el-button type="primary" @click="handleFileEnlarge(item, 1)">放大</el-button>
								</v-if>
								<el-button type="success" @click="handleFileEnlarge(item, 2)">转换</el-button>
								<el-button type="danger" @click="handleFileRemove(item, key)">删除</el-button>
							</el-space>
						</div>


						<el-row>
							<el-col :span="20">
								<el-text class="mx-1 test-center-view  " type="primary"
									style="font-size: 1.3em; padding-bottom: 5px;  " truncated="true">
									{{ item.filename }}
								</el-text>
							</el-col>
							<el-col :span="12">
								<el-text class="mx-1" type="info" style="font-size: 1.0em;">{{ item.filesizestr
									}}</el-text>
							</el-col>
							<el-col :span="12">
								<el-text class="mx-1 " type="info test-right-view" style="font-size: 1.0em;">
									{{ item.fileupdatetime }}</el-text>
							</el-col>
						</el-row>



					</div>


					<!-- 放大弹窗 -->
					<el-dialog v-model="isEnlargeImage" :modal-append-to-body="false" top="3%" width="80%">
						<img style="width:100%;" :src="enlargeImage">
					</el-dialog>
				</div>
			</el-space>
		</el-scrollbar>

	</div>

	<el-drawer title="上传文件" v-model="dialog" direction="ltr" custom-class="demo-drawer" ref="drawer" size="60%">
		<div class="demo-drawer__content">
			<el-form :model="form">
				<el-form-item label="活动名称" :label-width="formLabelWidth">
					<el-upload class="upload-demo" action="#" :limit="1" :auto-upload="false" :file-list="fileList"
						:on-success="uploadOnSuccess" :on-change="handleChange" :on-remove="removeFile"
						:on-exceed="limitFile">
						<el-button size="small" type="primary">点击上传</el-button>
						<template #tip>
							<div class="el-upload__tip">只能上传 dwg,jpg,jpeg,png 文件，且不超过 100MB</div>
						</template>
					</el-upload>
				</el-form-item>

			</el-form>
			<div class="demo-drawer__footer">
				<el-button @click="cancelForm">取 消</el-button>
				<el-button type="primary" @click="handleClose(confirmFile)" :loading="loading">
					{{ loading ? '提交中 ...' : '确定' }}</el-button>
			</div>

		</div>
	</el-drawer>

</template>




<script>
import { $axiosPost } from "@/api/publish/pub";
import { myPages } from "../../components/common";
import { uploaded } from "../../components/common";
import * as api from "@/api/folder/folder";
import dwglogo from '../../assets/dwg/dwglogo.jpg';
import pdflogo from '../../assets/dwg/pdf.jpeg';


import axios from 'axios';


import qs from "qs";
export default {
	name: "filesys",
	components: {
		myPages,
		uploaded
	},
	mounted() {
		this.bgHeight = document.body.scrollHeight - 190 + "px";
		this.getTableData();
		this.getPersonCompany();
		this.initData();

	},
	computed: {
		proStatus() { //上传状态
			if (this.pass) {
				return 'success'
			} else if (this.pass === false) {
				return 'exception'
			} else {
				return 'text'
			}
		}
	},
	data() {
		return {

			bgHeight: "",
			titleState: "添加", //弹框状态提示
			titleRoleState: "管理员", //添加角色弹框
			checkIndex: 0, //当前选中行的个数
			pkBasicuserStr: "", //存放当前选中行的主键，以‘，’隔开【删除，启用，封存】方法中使用
			tableData: [], //存放table数据
			editTable: {}, //存放修改后表单的数据
			editSta: "", //启用修改状态
			total: 0, //默认数据总数
			pageSize: 20, //每页的数据条数
			currentPage: 1, //默认开始页面
			dialogFormVisible: false,
			Mybeginvalidate: [], //存放有效期的变量 （作用：将有效期格式拆分为后台需要的形式）
			saveUrl: "", //是添加保存还是修改保存
			form: {
				//表单
				pkbasicuser: "", //主键
				pkcompany: "", //所属公司
				account: "", //账号
				password: "", //密码
				phone: "", //手机号
				iftjkmanage: "", //是否tjk管理员
				pkperson: "", //员工编号
				username: "", //用户名
				beginvalidate: "", //有效期开始日期
				endvalidate: "", //有效期结束日期
			},
			searchcontent: '',//搜索内容
			drawer: false,
			progress: 0, //上传进度
			pass: null, //是否上传成功
			isEnlargeImage: false, //放大图片
			enlargeImage: '', //放大图片地址
			imgList: [

			],

			fileList: [
			],

			dialog: false,
			loading: false,
			formLabelWidth: '80px',
			timer: null,
			// 文件总数
			FileCount: 0,
			// 每页显示条数
			limit_num: 20,
			// 当前页数
			Current_page: 0,

      Disting:false,
		};
	},


	methods: {

		vueSendMsg() {
      this.dialog = true
      this.Disting = true
			// this.$router.push({ path: 'Distinguish', query: { pktask: '197' } })
		},
		// 导航到一个带查询参数的路由

		initData() {

			// 获取文件总数
			api.getFileCount("").then((res) => {
				if (res.data.code == "success") {
					this.FileCount = res.data.data
				} else {
					this.$message.error(res.data.msg)
				}
			})


			this.fetchItems()

		},
		formatDate(time) {
			var date = new Date(time);
			var y = date.getFullYear();
			var m = date.getMonth() + 1;
			m = m < 10 ? ('0' + m) : m;
			var d = date.getDate();
			d = d < 10 ? ('0' + d) : d;
			var h = date.getHours();
			h = h < 10 ? ('0' + h) : h;
			var minute = date.getMinutes();
			var second = date.getSeconds();
			minute = minute < 10 ? ('0' + minute) : minute;
			second = second < 10 ? ('0' + second) : second;
			return y + '-' + m + '-' + d;
		},
		fetchItems() {
			api.qeryFolderList(this.Current_page, this.limit_num, "").then((res) => {
				console.log(res)

				if (res.data.code == "success") {
					this.DataProcess(res.data.data)
					this.imgList = res.data.data
					console.log(this.imgList)

				} else {
					this.$message.error(res.data.msg)
				}
			})

		},


		DataProcess(data) {
			for (let i = 0; i < data.length; i++) {
				var menony = (data[i].filesizestr / 1024 / 1024)
				data[i].filesizestr = menony.toFixed(2) + "MB"
				if (data[i].fileupdatetime != null)
					data[i].fileupdatetime = this.formatDate(data[i].fileupdatetime)
				//格式化日期输出

				if (data[i].filetype == "jpg" || data[i].filetype == "png" || data[i].filetype == "jpeg") {
					data[i].url = 'http://47.105.43.195:8886/GetThumbnail?md5=' + data[i].filemd5
				} else if (data[i].filetype == "dwg") {
					data[i].url = dwglogo
				} else if (data[i].filetype == "pdf") {
					data[i].url = pdflogo
				}
				// 仅保留"_" 之前的部分
				data[i].filename = data[i].filename.split('_')[1]

			}
		},



		handleChange(file, fileList) {
			this.fileList = fileList
			const isLt100M = file.size / 1024 / 1024 < 100;
			if (!isLt100M) {
				this.$message.error('上传文件大小不能超过 100MB!');
				this.fileList = this.fileList.pop()
			}
			// 添加时间戳到文件名
			file.name = `${Date.now()}_${file.name}`;
			return isLt100M
		},

		removeFile(file, fileList) {
			this.fileList = fileList
		},

		limitFile(file, fileList) {
			this.$message.error('只能单个文件上传')
		},

		confirmFile(file, fileList) {
			if (this.fileList.length == 0) {
				this.$message.error("请选择文件")
				this.loading = false
				return false
			}
			var param = new FormData()
			this.fileList.forEach((val, index) => {
				// 使用修改后的文件名上传
				param.append('file', val.raw, val.name)
			});

			console.log(param)
			api.addFile(param).then((res) => {
				console.log(res)
				if (res.data.code == "success") {
					this.$message.success("上传成功")
					this.loading = false
					this.fileList = []
					this.DataProcess(res.data.data)
					for (let i = 0; i < res.data.data.length; i++) {
						this.imgList.push(res.data.data[i])
					}
					console.log(this.imgList[this.imgList.length])

          if(this.Disting){
                // TODO:
            api.convertFile(this.imgList[this.imgList.length-1].filemd5).then((res) => {
              if (res.data.code == "success") {

                this.$notify.success({
                  title: "已经添加任务到图像识板块",
                  message: res.data.msg,
                });
                this.$router.push({ path: 'Distinguish'})
              }else if(res.data.code == "fail"){
                this.$message.warning(res.data.msg)
                this.$router.push({ path: 'Distinguish'})
              }
              else {
                this.$message.error(res.data.msg)
              }
              console.log(res)
            })
            this.Disting = false
          }

				} else {
					this.$message.error(res.data.msg)
					this.loading = false
				}

			})

		},

		handleClose(done) {
			if (this.loading) {
				return
			}

			this.$confirm('确定要上传文件吗？')
				.then((_) => {
					this.loading = true
					done()
				})
				.catch((_) => { })


		},
		cancelForm() {
			this.loading = false
			this.dialog = false
			clearTimeout(this.timer)
		},



		//查询所有
		getTableData() {
			let data = {
				pageSize: this.pageSize, //当前分页条数
				pageNo: this.currentPage, //当前页

			};
			this.$axiosPost("qryBasicUserBypage", data).then((response) => {
				this.tableData = response.data.data.list;
				this.total = response.data.data.totalRecords;
			});
		},

		//表单提交方法（保存方法）
		submitForm(formName) {
			if (
				this.editTable.states !== 1 &&
				this.editTable.states !== undefined &&
				this.editSta !== "enableEdit"
			) {
				this.$message.warning("只有自由状态才可修改！");
				return false;
			}
			if (this.Mybeginvalidate !== null && this.Mybeginvalidate.length !== 2) {
				this.$message.warning("请选择有效期！");
				return false;
			}
			if (this.Mybeginvalidate === null) {
				this.$message.warning("请选择有效期！");
				return false;
			}
			this.$refs[formName].validate((valid) => {
				this.verificationEditCom();
				if (valid) {
					const tempData = Object.assign({}, this.form);
					$axiosPost(this.saveUrl, qs.stringify(this.form)).then((res) => {
						if (res.data.code === "success") {
							this.$message({
								message: res.data.msg,
								type: "success",
							});
							this.dialogFormVisible = false;
							this.$refs[formName].resetFields();
							this.getTableData();
						} else {
							this.$notify.error({
								title: "错误",
								message: res.data.msg,
							});
						}
						this.editSta = ""; //将【修改】【启用修改状态清空】
						this.form.states = ""; //将状态清空
					});
				}
			});
		},
		//关闭弹框方法
		callOf(formName) {
			this.$nextTick(() => {
				this.$refs[formName].resetFields();
				this.dialogFormVisible = false;
			});
		},

		//查询当前登录人是否为tjk管理员
		getIfTjk() {
			$axiosPost("getCurrentUser", "").then((res) => {
				this.pkUserq = res.data.data.basicuser.pkbasicuser;
				if (res.data.data.basicuser.iftjkmanage === 1) {
					this.ifTjkMan = false;
				}
			});
		},
		//查询所有公司
		getPersonCompany() {
			$axiosPost("qryCompanyByWhere", "").then((response) => {
				this.companyList = response.data.data;
			});
			this.getIfTjk();
		},
		//修改验证多选框是否发生改变的方法
		verificationEditCom() {
			this.form.beginvalidate = this.Mybeginvalidate[0];
			this.form.endvalidate = this.Mybeginvalidate[1];
		},
		uploadOnProgress(e, file) { //开始上传
			console.log(e.percent, file)
			this.progress = Math.floor(e.percent)
		},
		uploadOnChange(file) {
			console.log("——————————change——————————")
			// console.log(file)
			if (file.status == 'ready') {
				console.log("ready")
				this.pass = null;
				this.progress = 0;
			} else if (file.status == 'fail') {
				this.$message.error("图片上传出错，请刷新重试！")
			}
		},
		uploadOnSuccess(e, file) { //上传附件
			console.log("——————————success——————————")
			this.pass = true;
			this.$message.success("上传成功")
			this.imgList.push({
				url: file.url,
				name: '新增图片'
			})
		},
		uploadOnError(e, file) {
			console.log("——————————error——————————")
			console.log(e)
			this.pass = false;
		},
		handleFileEnlarge(_file, Operation) { // 点击
			// Operation == 1 ? console.log("放大") : Operation == 2 ? console.log("转换") : null

			if (_file.url && Operation == 1) {
				console.log(_file.filename)
				//替换_file.url文本中的 GetThumbnail 这几个字
				let _url = _file.url.replace("GetThumbnail", "GetImage");
				this.enlargeImage = _url;
				console.log(_url)

				this.isEnlargeImage = !this.isEnlargeImage;
			}


			if (_file.url && Operation == 2) {
				this.$confirm('确认将文件 ' + _file.filename + ' 进行解析？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'prompt'
				}).then(() => {

					api.convertFile(_file.filemd5).then((res) => {
						if (res.data.code == "success") {

							this.$notify.success({
								title: "已经添加任务到图像识板块",
								message: res.data.msg,
							});
              this.$router.push({ path: 'Distinguish'})
						}else if(res.data.code == "fail"){
              this.$message.warning(res.data.msg)
              this.$router.push({ path: 'Distinguish'})
            }
            else {
							this.$message.error(res.data.msg)
						}
						console.log(res)
					})


				}).catch(() => { })
			}


		},


		handleFileName(file, i) { //修改名字
			console.log(file, i)

			this.$prompt("请输入新文件名：", "提示：", {
				confirmButtonText: '确定',
				cancelButtonText: '取消'
			}).then(({ value }) => {
				console.log(value)
				if (!value) {
					return false;
				}
				//可添加ajax
				this.$set(this.imgList[i], 'name', value)
				this.$message.success("操作成功")
			}).catch(() => { })
		},
		handleFileRemove(file, i) { //删除图片
			console.log(file, i)
			if (!file.url) {
				return false;
			}

			this.$confirm('是否删除此文件？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {

				api.deleteFile(file.filemd5).then((response) => {
					console.log(response)
					if (response.data.code == 200) {


						this.$message({
							type: 'success',
							message: '删除成功',

						})


					} else {
						this.$message.error(response.data.msg)
					}
				}).catch((meg) => console.log(meg))

				this.imgList.splice(i, 1)








			}).catch((meg) => console.log(meg))
		},


	},
};
</script>

<style lang="scss" src="@/assets/css/filesys.scss"></style>