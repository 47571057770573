<template>
	<div class="APP">
		<router-view></router-view>
	</div>
</template>

<script>
	export default {
		name: 'App',
		data() {
			return {
				bgHeight: "",
				isRouterAlives: true
			};
		},
		created() {
			this.bgHeight = document.body.scrollHeight + "px";
			console.log(this.$route);
		},
	};
</script>

<style>
	body,
	html,
	.APP {
		height: 100%;
		margin: 0;
		font-family: PingFangSemibold;
	}
</style>