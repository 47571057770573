<template>


  <v-if :key="item_List" v-show="item_List">
    <div class="grid-content bg-purple userMain hHun" v-show="item_List">

      <!-- <div class="searchBox"> -->


      <el-row class="row-bg" justify="space-between">
        <el-col :span="12">
          <div class="tabName">图像识别 - {{ TargetFile }}</div>


          <el-space>
            <!-- <div> <el-button type="primary" @click="draw_line">画直线</el-button></div> -->

            <div>
              <el-button type="primary" @click="tableData_draw_line">刷新</el-button>
            </div>

            <!-- <div> <el-button type="primary" @click="clearAllObject">清除</el-button></div> -->

            <div>
              <el-button type="primary" @click="drawer = true">打开文件浏览</el-button>
            </div>
            <div>
              <el-button type="primary" @click="ViewOriginalImage">查看原图</el-button>
            </div>


          </el-space>
        </el-col>

        <el-col :span="5">
          <el-dropdown @visible-change="handleVisible2" @command="backgroundTaskDetail">
            <span class="el-dropdown-link">
              当前服务器运行中的任务
              <el-icon class="el-icon--right">
                <arrow-down/>
              </el-icon>
            </span>
            <template #dropdown>
              <el-scrollbar max-height="500px">
                <el-dropdown-menu>

                  <p v-if="ProgressList.length == 0">
                    <el-dropdown-item>暂无正在运行的任111务...</el-dropdown-item>
                  </p>

                  <p v-else-if="ProgressList.length > 0">
                    <el-dropdown-item v-for="(item, index) in ProgressList" :key="index" :index="index"
                                      @click="selectTask(item)">
                      <p v-if="item.TransferComplete == 1"> 转换完成 | {{ item.taskName }} |
                        总数量：{{ item.totalProgress }}
                      </p>
                      <p v-else> 转换中 | {{ item.taskName }} | 预计用时：{{ item.EstimatedTime }} | 总数量：{{
                          item.totalProgress
                        }}张</p>


                    </el-dropdown-item>
                  </p>

                </el-dropdown-menu>
              </el-scrollbar>
            </template>
          </el-dropdown>

        </el-col>


      </el-row>


      <div class="tableCon" :style="{ height: bgHeight }"
           style="display: flex; flex-wrap: wrap ;flex-direction: column ;align-content: flex-end">
        <!-- <el-row :gutter="30" style="margin-bottom: 20px;">
          <el-col :span="12">-->


        <canvas id="myCanvas" style="width: 50%; height: 100%; border: 1px solid #ccc; "
                @mouseup="canvasClick"></canvas>

        <!-- </el-col>
          <el-col :span="12"> -->

        <el-scrollbar style="width: 49%;  margin-left: 20px;" ref="scrollbarRef">
          <el-table :data="tableData" style=" " ref="multipleTable" :cell-class-name="getCellClass" id="table1"
                    @row-click="rowClick" @row-dblclick="doubleClick">

            <!-- <el-table-column prop="id" label="对象ID" width="90px"> </el-table-column> -->
             <!-- zc table -->
            <el-table-column prop="index" label="编号"></el-table-column>
            <el-table-column prop="Start" label="起始X,Y坐标"></el-table-column>
            <el-table-column prop="End" label="终点X,Y坐标"></el-table-column>
            <!-- <el-table-column prop="Pixel" label="像素长度"> </el-table-column> -->
            <el-table-column prop="Actual" label="实际长度"></el-table-column>
            <el-table-column prop="ishanger" label="是否带弯钩(ishanger)"></el-table-column>
            <el-table-column prop="bent" label="是否中间有折弯(bent)"></el-table-column>
          </el-table>
        </el-scrollbar>

        <!-- </el-col> -->
        <!-- </el-row> -->
      </div>
    </div>
    <!-- </div> -->
  </v-if>

  <el-dialog v-model="centerDialogVisible" title="成功识别" width="500" center>
    <span>
      目前已经成功识别图纸到数据库，请问是否现在打开？
    </span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="SuccessfullyOpenedEvent">
          打开
        </el-button>
      </div>
    </template>
  </el-dialog>
  <el-dialog v-model="Identifi" title="已经识别" width="500" center>
    <span>
      图纸已经识别过，但检测到数据异常，是否重新识别？
    </span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="Identifi = false">取消</el-button>
        <el-button type="primary" @click="confirmClick(1)">
          重新识别
        </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 放大弹窗 -->
  <el-dialog v-model="isEnlargeImage" :modal-append-to-body="false" top="3%" width="80%" z-index="2001">
    <img style="width:100%;" :src="OriginalImage_url">
  </el-dialog>

  <el-drawer v-model="drawer" direction='ltr' z-index="2000">
    <template #header>
      <h4>选择图纸</h4>
    </template>
    <template #default>
      <el-tree style="max-width: 100%" :props="props" :load="LazyLoadingData" lazy @node-click="handleNodeClick"/>
    </template>

    <template #footer>

      <el-text size="small">选择图纸为： {{ TargetFile }}</el-text>
      <div style="flex: auto">
        <el-button type="success" round @click="ViewOriginalImage">查看</el-button>
        <el-button type="primary" round @click="Recognition">识别</el-button>
      </div>
    </template>
  </el-drawer>

  <div class="grid-content bg-purple userMain hHun" v-show="!item_List">
    <div class="tabName">当前可识别的文件列表为</div>


    <button type="primary" @click='drawer = true'>打开选择图纸</button>
  </div>

  <el-dialog v-model="LiftFileDialogVisible" title="选择图纸识别模式" width="500">

    <el-select v-model="region" placeholder="点击选择识别模式" @change ="selectV">
      <li v-for="(item, index) in gridData" :key="index">
        <el-option :label="item.name" :value="item.id" ></el-option>
      </li>

    </el-select>

    <template #footer>
      <div class="dialog-footer">
        <el-button @click="LiftFileDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmClick(0)">
          识别
        </el-button>
      </div>
    </template>
  </el-dialog>

  <el-dialog :close-on-click-modal="false" title="修改参数" v-model="editDialogVisible" v-if="editDialogVisible">
    <el-form class="companyform" label-width="110px" label-position="left" :rules="rules" :model="form" :inline="true"
             ref="form">

      <el-row type="flex" class="row-bg">
        <el-col :span="12">
          <el-form-item label="钢筋ID" prop="comname">
            <el-input s :disabled="true"
                      v-model="form.id"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="比例尺 1：x" prop="comname42">
            <el-input-number v-model="Proportion" :min="0" :max="100" :step="0.001" :disabled="true"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg">
<!--        <el-col :span="12">-->

<!--          <el-form-item label="是否带弯钩" prop="isinteractive">-->
<!--            <el-select style="width: 160px !important;" v-model="form.ishanger" placeholder="是否带弯钩">-->
<!--              <el-option key="0" label="否" value="0"></el-option>-->
<!--              <el-option key="1" label="是" value="1"></el-option>-->
<!--            </el-select>-->
<!--            <el-input style="width: 160px !important;" v-model="form.ishanger"  placeholder="是否带弯钩"></el-input>-->

<!--          </el-form-item>-->
<!--        </el-col>-->
        <el-col :span="12">
          <el-form-item label="是否直线" prop="isinteractive">
            <el-select style="width: 160px !important;" v-model="form.bent" placeholder="是否中间有折弯">
              <el-option key="0" label="否" value="0"></el-option>
              <el-option key="1" label="是" value="1"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg">
        <el-col :span="12">
          <el-form-item label="实际起始[x,y]" prop="comname11">
            <el-input-number v-model="form.Start[0]" :min="0" :max="999999" :step="1" @change="handleChange"/>
            <el-input-number v-model="form.Start[1]" :min="0" :max="999999" :step="1" @change="handleChange"/>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际终点[x,y]" prop="comname131">
            <el-input-number v-model="form.End[0]" :min="0" :max="999999" :step="1" @change="handleChange"/>
            <el-input-number v-model="form.End[1]" :min="0" :max="999999" :step="1" @change="handleChange"/>
          </el-form-item>
        </el-col>
        <!-- <el-col :span="12">
          <el-form-item label="实际起始[x,y]" prop="comname332">

          </el-form-item>
          <el-form-item label="实际终点[x,y]" prop="comname213">

          </el-form-item>
        </el-col> -->
      </el-row>

      <el-row type="flex" class="row-bg">


      </el-row>
      <el-row type="flex" class="row-bg">
        <el-col :span="12">
          <el-form-item label="像素点" prop="comname55">
            <el-input :disabled="true"
                      v-model="form.Pixel"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="实际长度" prop="comname66">
            <el-input :disabled="true"
                      v-model="form.Actual"></el-input>
          </el-form-item>
        </el-col>
      </el-row>


    </el-form>
    <div slot="footer" class="dialog-footer ar">
      <el-button @click="callOf()">取 消</el-button>
      <el-button type="primary" @click="submitForm()">保 存</el-button>
    </div>
  </el-dialog>

</template>


<script>

import Mx from 'mxdraw';
import * as api from "@/api/folder/folder";
import {ElLoading, ElMessage} from 'element-plus';


export default {

  components: {  // 注册组件
    Mx,
  },


  data() {
    return {

      scroll: 0,

      bgHeight: '',
      bgWidth: '',
      draw: null,
      drawer: false,
      Scale_Multiple: 1,
      item_List: true,


      dropdown: true, // 识别程序下拉框 是否显示
      isEnlargeImage: false,  // 是否放大图片
      OriginalImage_url: '',   // 原始图片地址


      listLayer: [],    // 用于存放图纸数据
      tableData: [],        // 表格数据
      ProgressList: [],    // 正在识别任务进度条数据
      indexMethod: 1, // 表格索引
      TargetFile: "NULL", // 当前目标文件

      PixelLengthThreshold: [50, 8000],    // 像素长度阈值  
      ActualLengthThreshold: [50, 6000],  // 实际长度阈值
      ProScope: [50, 50, 5600, 5500], // [xmin, xmax, ymin, ymax]   // 加工范围


      Identifi: 0,   // 异常数据提醒框 是否显示
      Proportion: 1, // 实际长度与像素长度的比例
      LiftFileDialogVisible: false,    //  图纸识别程序选择 是否显示
      editDialogVisible: false,   // 修改信息 对话框 是否显示
      centerDialogVisible: false,     // 识别成功提示框 是否显示

      region: '赣深三局',//   默认选择识别程序
      regionID:1,
      gridData: [{       //识别程序列表
        name: '赣深三局',
        id: 1
      }, {
        name: '2-4叠合板',
        id: 2
      },{
        name:"中山区",
        id:3
      }
      ],

      form: {// 修改信息表单
        id: '',
        Start: [],
        End: [],
        ActStart: [],
        ActEnd: [],
        Pixel: '',
        Actual: '',
        ishanger: '',
        bent: '',
      },


      props: {    // 树形控件属性
        label: 'name',
        path: 'path',
        isLeaf: 'leaf'
      },
    }
  },


  // 项目初始化 
  mounted() {
    this.bgHeight = document.body.scrollHeight - 175 + "px"; // 设置背景高度为屏幕高度减去190px


    // 动态加载 js库核心代码
    Mx.loadCoreCode().then(async () => {
      Mx.MxFun.createMxObject({
        canvasId: "myCanvas", // canvas元素的id
        // cadFile: "http://localhost:8886/#",
        callback(mxDraw, dom) {
          mxDraw.setViewColor(0xffffff); // 设置背景颜色

        },

      });

      this.draw = Mx.MxFun.getCurrentDraw();
      const cnv = document.getElementById('myCanvas')
      cnv.style.width = document.getElementById('table1').offsetWidth + 'px'


      this.draw.zoomW(this.xy2Doc(1, 1), this.xy2Doc(8000, 8000)) // 设置缩放比例

      this.Scale_Multiple = this.draw.getViewWidth() / 8000 // 获取缩放比例

      this.MxDraw_f5();


    })

    // 如果传入参数 则加载图纸
    var data = this.$route.query.pktask

    if (data !== undefined) {
      this.loading = ElLoading.service({
        lock: false,
        text: '正在查询数据中...',
        background: 'rgba(0, 0, 0, 0.7)',
      })

      api.getDatapktask(data).then(res => {
        if (res.data.code === "success") {
          console.log("路径：" + res.data.data.DrawPath + "图纸：" + res.data.data.DrawName)
          console.log(res.data.data.DataList)

          this.TargetFile = res.data.data.DrawPath + "\\" + res.data.data.DrawName + ".png"

          if (res.data.data.DataList.length === 0) {

            this.$message.error("数据查询异常")
            return
          }
          this.DataProcess(res.data.data.DataList)

          this.DrawOriginPoint();
          Mx.MxFun.getCurrentDraw().updateDisplay();
          this.$message.success("获取 " + this.TargetFile + " 的数据成功")
        } else if (res.data.code === "fail") {
          this.$message.error("数据查询异常：" + res.data.msg)

        } else {
          this.$message.error("数据查询错误：" + res.data.msg + "，请检查数据库是否含有图纸数据")
        }

      })

      this.drawer = false
      this.loading.close()


    } else {
      this.drawer = true
    }


  },


  methods: {

    canvasClick(event) {
      var ccc = Mx.MxFun.getCurrentDraw().getMxCurrentSelect()

      const table = document.getElementById('table1')
      const rows = table.getElementsByTagName('tr');
      for (let i = 0; i < rows.length; i++) {
        rows[i].style.backgroundColor = '';
      }

      if (ccc.length > 0) {
        for (let i = 0; i < ccc.length; i++) {
          rows[ccc[i]].style.backgroundColor = 'rgba(255, 255, 0, 0.5)';
        }
        this.$refs.scrollbarRef.setScrollTop(rows[ccc[0]].offsetTop - 100)

      }
    },

    calculateDistance(x1, y1, x2, y2) {
      // 计算两点在x轴和y轴上的距离差
      var dx = x2 - x1;
      var dy = y2 - y1;
      // 应用勾股定理公式
      return Math.sqrt(dx * dx + dy * dy);
    },
    submitForm() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const tempData = Object.assign({}, this.form);
          console.log(tempData)
          let editdata = {
            "index": tempData.id,
            "Start": tempData.Start[0] + "," + tempData.Start[1],
            "End": tempData.End[0] + "," + tempData.End[1],
            // "ActStart": tempData.ActStart[0] + "," + tempData.ActStart[1],
            // "ActEnd": tempData.ActEnd[0] + "," + tempData.ActEnd[1],
            "Pixel": tempData.Pixel,
            "Actual": tempData.Actual,
            "ishanger": tempData.ishanger ,
            "bent": tempData.bent == "1" ? "1" : "0",
          }
          console.log(editdata)
          api.seteditData(editdata, this.TargetFile).then(res => {
            if (res.data.code == "success") {
              this.$message.success(res.data.msg)
              this.editDialogVisible = false;
              this.SuccessfullyOpenedEvent();

              this.MxDraw_f5();
            } else {
              this.$message.error(res.data.data)
              this.editDialogVisible = false;
            }
          })
        }
      })
    },
    handleChange(value) {

      this.form.Actual = this.calculateDistance(
          this.form.Start[0],
          this.form.Start[1],
          this.form.End[0],
          this.form.End[1]
      )
      this.form.Pixel = Math.floor(this.form.Actual * this.Proportion)
    },
    callOf() {
      this.editDialogVisible = false;
    },
    doubleClick(row, column, event) {
      this.edit(row); //调用修改方法
    },
    edit(row) {
      this.saveUrl = 'editDate'; //调用修改方法

      this.editDialogVisible = true;
      this.form.id = row.index;
      // 分割字符串 '[111,222]' 成两个数 111 和 222
      this.form.Start = [parseInt(row.Start.split(',')[0].split('[')[1]), parseInt(row.Start.split(',')[1].split(']')[0])];
      this.form.End = [parseInt(row.End.split(',')[0].split('[')[1]), parseInt(row.End.split(',')[1].split(']')[0])];
      // this.form.Pixel = row.Pixel;    // 像素长度
      // this.form.Proportion = (row.Actual / row.Pixel).toFixed(4)    // 比例尺
      // this.form.Actual = Math.floor(this.form.Pixel * this.form.Proportion)
      this.form.Actual = row.Actual;    // 实际长度

      this.form.Pixel = Math.floor(this.form.Actual * this.Proportion)
      this.form.ishanger = row.ishanger  ;
      this.form.bent = row.bent == '是' ? "1" : "0";

    },

    rowClick(row, column, event) {

      this.draw.addMxCurrentSelect(row.id, false, true)

      // console.log(this.draw.getMxCurrentSelect())

      this.MxDraw_f5();

    },

    DrawOriginPoint() {
      console.log("this.draw")
      let lines = new Mx.MxDbArrow()
      lines.setLineWidth(10)
      // // 设置线段的终点

      lines.innerOffset = this.getScreenPixel(3)
      lines.outerOffset = this.getScreenPixel(10)
      lines.topOffset = this.getScreenPixel(8)
      lines.startPoint = this.xy2Doc(0, -2)
      lines.endPoint = this.xy2Doc(0, 800 * this.Scale_Multiple)
      lines.isFill = true
      lines.setColor(0xff0000);
      this.draw.addMxEntity(lines);
      let linesx = new Mx.MxDbArrow()
      linesx.setLineWidth(10)
      // // 设置线段的终点

      linesx.innerOffset = this.getScreenPixel(3)
      linesx.outerOffset = this.getScreenPixel(10)
      linesx.topOffset = this.getScreenPixel(8)
      linesx.startPoint = this.xy2Doc(-2, 0)
      linesx.endPoint = this.xy2Doc(800 * this.Scale_Multiple, 0)
      linesx.isFill = true
      linesx.setColor(0x00ff00);
      this.draw.addMxEntity(linesx);
      Mx.MxFun.getCurrentDraw().zoomCenter(300, 0);
      this.MxDraw_f5();
    },
    getScreenPixel(pixel, isFontSize) {
      let _pixel = Mx.MxFun.screenCoordLong2World(isFontSize ? pixel : pixel - pixel / 3)
      _pixel = Mx.MxFun.worldCoordLong2Doc(_pixel)
      return _pixel
    },

    // 识别按钮
    Recognition() {
      if (this.TargetFile == "NULL") {
        this.$message.error("请选择图纸")
        return
      }
      this.LiftFileDialogVisible = true
    },

    SaveProscpoe() {
      console.log(this.ProScope)
      this.tableData_draw_line()
      // this.draw_Rect(this.ProScope[0], this.ProScope[1], this.ProScope[2], this.ProScope[3])
    },

    handleVisible2(visible) {
      if (visible) {
        api.getProgress().then(res => {
          if (res.data.code == "success") {
            this.ProgressList = res.data.data
            console.log('=========>ZC' + this.ProgressList)
          }
        })

      }

    },

    backgroundTaskDetail(command){
      // ElMessage(`click on item ${command}`)
      debugger
      console.log('zc========>'+ command)
    },

    SuccessfullyOpenedEvent() {
      this.centerDialogVisible = false
      api.getDataDistinguish(this.TargetFile).then(res => {
        console.log(res.data.data)
        this.DataProcess(res.data.data)

        // this.tableData_draw_line()
        // this.draw_Rect(this.ProScope[0], this.ProScope[1], this.ProScope[2], this.ProScope[3])
        // console.log(this.tableData)
        // 画矩形
        this.DrawOriginPoint()


      })
      this.drawer = false
      this.item_List = true

    },

    ViewOriginalImage() {
      // api.OriginalImage(this.TargetFile).then((res) => {
      //   console.log(res.data.data)

      // })
      this.OriginalImage_url = "http://47.105.43.195:8886/OriginalImage?path=" + this.TargetFile.split("\\")[0] + "&name=" + this.TargetFile.split("\\")[1]
      this.isEnlargeImage = true

    },

    selectV(value){
      console.log(value)
      this.regionID = value

    },
    confirmClick(Again) {
      this.Identifi = false
      if (this.region == "") {
        this.$message.error("请选择识别模式")
        return
      }

      this.loading = ElLoading.service({
        lock: false,
        text: '正在识别中...（预计1分钟内）',
        background: 'rgba(0, 0, 0, 0.7)',
      })

     if ( this.regionID == 0 || this.regionID > this.gridData.length)
       return

      console.log("confirmClick"+ this.regionID)
      api.executeDistinguish(this.TargetFile, Again, this.regionID).then((res) => {
        this.distData(res)
      })


      this.LiftFileDialogVisible = false

    },

    distData(res) {
      if (res.data.code == "success") {

        this.centerDialogVisible = true
        this.drawer = false
        this.item_List = true
        this.loading.close()

      } else if (res.data.code == "fail") {

        api.getDataDistinguish(this.TargetFile).then(res => {
          console.log("getDataDistinguish \n" +res.data.data)
          if (res.data.data.length == 0) {
            // this.$message.error("未识别到数据")
            this.Identifi = true
            return
          }
          this.DataProcess(res.data.data)

          // console.log("运行到这了的")
          // this.tableData_draw_line()
          // this.draw_Rect(this.ProScope[0], this.ProScope[1], this.ProScope[2], this.ProScope[3])
          // console.log(this.tableData)
          // 画矩形
          this.DrawOriginPoint();
          console.log(this.draw)

        })
        this.drawer = false
        this.item_List = true
        this.loading.close()

      } else {
        this.$message.error(res.data.msg)
        this.loading.close()

      }
    },

    LazyLoadingData(node, resolve) {
      if (node.level === 0) {
        api.getRootPath().then((res) => {
          if (res.data.code == "success") {
            console.log(res.data.data)
            return resolve(res.data.data);
          }
        })
        return resolve([]);
      }
      if (node.level == 1) {
        api.getDrawImage(node.data.path).then((res) => {
          if (res.data.code == "success") {
            if (res.data.data.leaf == true) {
              this.TargetFile = res.data.data.path
              console.log(this.TargetFile)
            }
            return resolve(res.data.data);
          }
        })
        return resolve([]);
      }


    },

    handleNodeClick(data) {
      if (data.leaf == true) {
        this.TargetFile = data.path + data.name
        console.log(data)

      }
    },

    DataProcess(data) {


      this.clearAllObject();


      console.log(data)
      if (data.length == 0)
        return;


      this.tableData = []
      // 遍历data
      for (let i = 0; i < data.length; i++) {


        // 获取起始点
        let Start = data[i].actStart.split(',')
        // 获取终点
        let End = data[i].actEnd.split(',')
        // 设置线段的起始点

        // 实例化线段对象line
        let line = new Mx.MxDbLine()
        line.setPoint1(this.xy2Doc(Start[0] * this.Scale_Multiple, Start[1] * this.Scale_Multiple));
        // 设置线段的终点
        line.setPoint2(this.xy2Doc(End[0] * this.Scale_Multiple, End[1] * this.Scale_Multiple));
        // 设置颜色
        line.setColor(0x010101);

        // 将线段对象添加到画布中
        var objid = this.draw.addMxEntity(line);
        this.MxDraw_f5();


        this.tableData.push({})
        this.tableData[i].id = objid
        this.tableData[i].index = data[i].id
        this.tableData[i].Start = "[" + data[i].actStart + "]"
        this.tableData[i].End = "[" + data[i].actEnd + "]"

        this.tableData[i].Pixel = data[i].pixLen
        if (data[i].actlen >= 65536 || data[i].actlen <= 0) {
          this.tableData[i].Actual = "空"
        } else {
          this.tableData[i].Actual = data[i].actlen
        }
        this.tableData[i].ishanger = data[i].ishanger
        this.tableData[i].bent = data[i].bent == "1" ? '是' : '否'
      }

      this.Proportion = (this.tableData[data.length-1].Actual / this.tableData[data.length-1].Pixel).toFixed(4)    // 比例尺

      console.log(this.tableData)
    },

    xy2Doc(x, y) {
      return Mx.MxFun.screenCoord2Doc(x, y, 0)
    },

    MxDraw_f5() {

      Mx.MxFun.getCurrentDraw().updateDisplay();
      // Mx.MxFun.getCurrentDraw().zoomInitialStates();

    },

    getCellClass({row, column}) {
      // if (column.property === 'ishanger') {
      //   if (row.ishanger === '是') {
      //     return 'cell_Green';
      //   }
      // }
      if (column.property === 'bent') {
        if (row.bent === '是') {
          return 'cell_Highlight';
        }
      }

      return '';

      if (column.property === 'Start') {
        // 将row.Start转换为数组
        let Start = row.Start.replace('[', '').replace(']', '').split(',')

        // 判断是否在加工范围内
        if (this.isPointInRect(Start[0], Start[1]) || Start == "")
          return 'cell_Highlight';

      }
      if (column.property === 'End') {
        // 将row.End转换为数组
        let End = row.End.replace('[', '').replace(']', '').split(',')
        // 判断是否在加工范围内
        if (this.isPointInRect(End[0], End[1]) || End == "")
          return 'cell_Highlight';
      }


      if (column.property === 'Pixel' && (row.Pixel < this.PixelLengthThreshold[0] || row.Pixel > this.PixelLengthThreshold[1])) return 'cell_Highlight';
      if (column.property === 'Actual' && (row.Actual < this.ActualLengthThreshold[0] || row.Actual > this.ActualLengthThreshold[1])) return 'cell_Highlight';


      return '';
    },

    clearAllObject() {

      /** ------------------ */

      Mx.MxFun.getCurrentDraw().clearAllObject();
      this.MxDraw_f5();
    },

    tableData_draw_line() {
      this.clearAllObject();

      // 遍历tableData数据
      for (let i = 0; i < this.tableData.length; i++) {

        // 获取起始点
        let Start = this.tableData[i].Start.replace('[', '').replace(']', '').split(',')
        // 获取终点
        let End = this.tableData[i].End.replace('[', '').replace(']', '').split(',')
        // 设置线段的起始点

        // 实例化线段对象line
        let line = new Mx.MxDbLine()
        line.setPoint1(this.xy2Doc(Start[0] * this.Scale_Multiple, Start[1] * this.Scale_Multiple));
        // 设置线段的终点
        line.setPoint2(this.xy2Doc(End[0] * this.Scale_Multiple, End[1] * this.Scale_Multiple));
        // 设置颜色
        line.setColor(0x010101);

        // 将线段对象添加到画布中
        this.tableData[i].id = this.draw.addMxEntity(line);
        this.MxDraw_f5();

      }
      this.DrawOriginPoint();
      console.log(this.tableData)
    },

    async draw_Rect(x1, y1, x2, y2) {
      /** 画一个贴合框画布的矩形 */
      let rect = new Mx.MxDbRect();
      rect.pt1 = this.xy2Doc(x1 * this.Scale_Multiple, y1 * this.Scale_Multiple)

      rect.pt2 = this.xy2Doc(x2 * this.Scale_Multiple, y2 * this.Scale_Multiple)
      rect.setColor(0xFF22)
      this.draw.addMxEntity(rect);

    },

    async draw_line() {

      // 实例化线段对象line
      let line = new Mx.MxDbLine()
      const getPoint = new Mx.MrxDbgUiPrPoint()
      const pt1 = await getPoint.go()
      if (!pt1) return
      // 设置线段的起始点
      line.setPoint1(pt1);
      // 设置颜色
      line.setColor(0xFF22);
      // 动态绘制函数
      getPoint.setUserDraw((currentPoint, worldDrawComment) => {
        // 设置线段第二个点位置
        line.setPoint2(currentPoint);
        // 绘制线段对象
        worldDrawComment.drawCustomEntity(line);
      })
      // 将第二次鼠标点击的位置设置为线段的结束点
      const pt2 = await getPoint.go()
      if (!pt2) return
      line.setPoint2(pt2)
      // 获取控件对象并将线段对象line添加到画布中
      this.draw.addMxEntity(line);
      this.MxDraw_f5();
    },


    isPointInRect(x, y) {

      const x1 = this.ProScope[0];
      const y1 = this.ProScope[1];
      const x2 = this.ProScope[2];
      const y2 = this.ProScope[3];


      // 如果点的X、Y坐标在矩形的X、Y坐标和宽、高的范围内，则点在矩形内
      return !(x >= x1 && x <= x2) && (y >= y1 && y <= y2);
    },

  }


}
</script>


<style>
/* 高亮所有单元格 */
.el-table .cell_Highlight {
  /* background-color: #f61509ed; */
  color: #f61509ed;
  /* 可以更换为你想要的颜色 */
}

.el-table .cell_Green {
  /* background-color: #46BB86; */
  color: #46BB86;
  /* 可以更换为你想要的颜色 */
}


.container {
  position: relative;
}

.left-aligned-button {
  position: absolute;
  left: 0;
  top: 0;
  /* 根据需要调整到合适的垂直位置 */
}

.example-showcase .el-dropdown-link {
  cursor: pointer;
  color: var(--el-color-primary);
  display: flex;
  align-items: center;
}


.el-table--scrollable-x .el-table__body-wrapper {
  z-index: 2;
}
</style>