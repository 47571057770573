<template>
	<div class="grid-content bg-purple userMain hHun">
		<div class="tabName">网片查看</div>
		<div class="btnList">
			<el-button type="primary" size="small" plain @click="getMeshTaskInfoToTJKMes()">同 步</el-button>
			<!-- <el-button type="primary" size="small" plain @click="getButtonState('add')">增加</el-button>
      <el-button type="success" size="small" plain @click="getButtonState('edit')">修改</el-button>
      <el-button type="danger" size="small" plain @click="getButtonState('delete')">删除</el-button>
      <el-button type="warning" size="small" plain @click="getButtonState('enable')">启用</el-button>
      <el-button type="info" size="small" plain @click="getButtonState('sealup')">封存</el-button>
      <el-button size="small" color="#626aef" plain @click="getButtonState('enableEdit')">启用修改</el-button> -->
			<el-button size="small" color="#626aef" plain @click="bindEuq">绑定设备</el-button>
			<div class="disL wFive hHun fr ar pageContent">
				<my-pages :total="total" :page-size="pageSize" @handleSizeChangeSub="handleSizeChangeFun"
					@handleCurrentChangeSub="handleCurrentChangeFun"></my-pages>
			</div>
		</div>

		<div class="tableCon" :style="{height:bgHeight}">
			<el-table highlight-current-row stripe height="100%" :data="tableData" ref="multipleTable"
				:cell-style="{ 'text-align': 'center'}" :header-cell-style="{ 'text-align': 'center' }"
				@selection-change="handleSelectionChange" @row-click="rowClick" @row-dblclick="rowDbClick">
				<!-- @row-click="rowClick" @row-dblclick="rowDbClick" -->
				<!-- <el-table-column type="index"></el-table-column> -->
				<el-table-column type="selection" width="30"></el-table-column>
				<el-table-column prop="pktask" label="ID" width="60"></el-table-column>
				<el-table-column prop="taskname" label="文件名" width="300">
					<el-table-column prop="taskname" width="300">
						<template #header slot-scope="scope">
							<el-input v-model="searchTaskname" clearable @change="getTableData"></el-input>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="states" label="状态" width="100">
					<!-- <el-table-column prop="states" width="150">
            <template #header slot-scope="scope">
              <el-select v-model="searchState" placeholder="状态" clearable @change="getTableData">
                <el-option v-for="item in filterStatesList" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </template>
          </el-table-column> -->
				</el-table-column>
				<el-table-column prop="meshindex" label="网片序号" width="130">
					<el-table-column prop="meshindex" width="130">
						<template #header slot-scope="scope">
							<el-input v-model="searchMeshindex" clearable @change="getTableData"></el-input>
						</template>
						<template #default="{row,$index}">
							<span>page_{{row.meshindex}}</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="meshname" label="网片名称" width="250">
					<el-table-column prop="meshname" width="250">
						<template #header slot-scope="scope">
							<el-input v-model="searchMeshname" clearable @change="getTableData"></el-input>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="meshlong" label="长*宽" width="100">
					<template #default="{row,$index}">
						<span>{{row.meshlong}}*{{row.width}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hdiameter" label="横/纵直径" width="150">
					<template #default="{row,$index}">
						<span>{{row.hdiameter}}/{{row.zdiameter}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="plannum" label="计划量" width="100"></el-table-column>
				<el-table-column prop="finishnum" label="完成量" width="100"></el-table-column>
				<el-table-column prop="weight" label="重量KG" width="120"></el-table-column>
				<el-table-column prop="hnum" label="横筋根数" width="100"></el-table-column>
				<el-table-column prop="znum" label="纵筋根数" width="100"></el-table-column>
				<el-table-column prop="equname" label="生产设备" width="150"></el-table-column>
				<el-table-column prop="remark" label="备注" width="250"></el-table-column>
				<el-table-column prop="psnname " label="创建人" width="100"></el-table-column>
				<el-table-column prop="createdate" label="创建时间" width="220px"></el-table-column>
				<!-- <el-table-column prop="" label="操作" width="100px" fixed="right">
          <template #default="{row,$index}">
            <el-button type="text" circle @click.stop="bdBom(row)">绑定设备</el-button>
          </template>
        </el-table-column> -->
			</el-table>
		</div>
		<el-dialog :close-on-click-modal="false" title="绑定设备" v-model="dialogBindEqu" width="1000px"
			v-if="dialogBindEqu">
			<el-table highlight-current-row stripe height="500" :data="equData" ref="multipleTable"
				:cell-style="{ 'text-align': 'center'}" :header-cell-style="{ 'text-align': 'center' }">
				<el-table-column type="index"></el-table-column>
				<el-table-column prop="equcode" label="设备编码" width="150">
					<el-table-column prop="equcode" width="150">
						<template #header>
							<el-input clearable v-model="searchEqucode" placeholder="" @change="getEquData" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="equname" label="设备名称" width="150">
					<el-table-column prop="equname" width="150">
						<template #header>
							<el-input clearable v-model="searchEquname" @change="getEquData" placeholder="" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="xinghao" label="设备型号" width="150">
					<el-table-column prop="xinghao" width="150">
						<template #header>
							<el-input clearable v-model="searchXinghao" @change="getEquData" placeholder="" />
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="ipaddress" label="ip地址" width="180"></el-table-column>
				<el-table-column prop="isinteractive" label="是否交互" width="150">
					<el-table-column prop="isinteractive" width="150">
						<template #default="{row,$index}">
							<span v-if="row.isinteractive===0">否</span>
							<span v-else>是</span>
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="way" label="交互方式" width="150"></el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋直径范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmixdiameter}}-{{row.hmaxdiameter}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋直径范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zmindiameter}}-{{row.zmaxdiameter}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋长度范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmixlong}}-{{row.hmaxlong}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋长度范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zminlong}}-{{row.zmaxlong}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋抽头范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmintou}}-{{row.hmaxtou}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋留尾范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hminwei}}-{{row.hmaxwei}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋抽头范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zmintou}}-{{row.zmaxtou}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋留尾范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zminwei}}-{{row.zmaxwei}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="横筋间距范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.hmixspace}}-{{row.hmaxspace}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="hmixdiameter" label="纵筋间距范围" width="150">
					<template #default="{row,$index}">
						<span>{{row.zminspace}}-{{row.zmaxdiameter}}</span>
					</template>
				</el-table-column>
				<el-table-column prop="" label="操作" width="100px" fixed="right">
					<template #default="{row,$index}">
						<el-button type="text" circle @click.stop="bindSaveEqu(row)">绑定</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-dialog>
	</div>
</template>

<script>
	import {
		$axiosPost,
	} from '@/api/publish/pub'
	import {
		myPages,
	} from '../../components/common';

	export default {
		name: 'basicUser',
		components: {
			myPages,
		},
		mounted() {
			this.bgHeight = document.body.scrollHeight - 190 + "px";
			this.getTableData();
		},
		data() {
			return {
				bgHeight: '',
				checkIndex: 0, //当前选中行的个数
				pkTaskStr: '', //存放当前选中行的主键，以‘，’隔开【删除，启用，封存】方法中使用
				tableData: [], //存放table数据
				total: 0, //默认数据总数
				pageSize: 20, //每页的数据条数
				currentPage: 1, //默认开始页面
				searchTaskname: '',
				searchState: '',
				searchMeshname: '',
				searchMeshindex: '',
				filterStatesList: [{
						value: '1',
						label: '自由'
					},
					{
						value: '2',
						label: '启用'
					},
					{
						value: '3',
						label: '封存'
					}
				],
				/* ==========绑定设备相关 */
				equData: [],
				dialogBindEqu: false,
				searchEqucode: '',
				searchEquname: '',
				searchXinghao: '',
			};
		},
		methods: {

			//网片查询所有
			getTableData() {
				let data = {
					pageSize: this.pageSize, //当前分页条数
					pageNo: this.currentPage, //当前页
					taskname: this.searchTaskname,
					states: this.searchState,
					meshname: this.searchMeshname,
					meshindex: this.searchMeshindex,
				}
				this.$axiosPost('qureyMeshtaskPage', data).then(response => {
					this.tableData = response.data.data.list;
					this.total = response.data.data.totalRecords;
				})
			},

			//单击行方法
			rowClick(row, column, event) {
				this.$refs.multipleTable.toggleRowSelection(row); //点击选中
			},
			//双击行操作
			rowDbClick(row, column, event) {
				this.$refs.multipleTable.clearSelection(); //清空选中行
				this.$refs.multipleTable.toggleRowSelection(row, true); //点击选中
				console.log(row)
				this.$router.push({
					path: 'Distinguish',
					query: {
						pktask: row.pktask
					}
				})
			},
			bindEuq() {
				if (this.splicingPk(100) !== false) {
					this.dialogBindEqu = true;
					this.getEquData();
				}
			},
			getEquData() {
				let data = {
					pageSize: 100, //当前分页条数
					pageNo: 1, //当前页
					equcode: this.searchEqucode,
					equname: this.searchEquname,
					xinghao: this.searchXinghao,
				}
				this.$axiosPost('qureyMequipment', data).then(response => {
					this.equData = response.data.data.list;
				})
			},
			//同步方法
			getMeshTaskInfoToTJKMes() {
				this.$axiosPost('meshTaskInfoToTJKMes', '').then(response => {
					//this.equData = response.data.data.list;
					console.log(response);
				})
			},
			bindSaveEqu(rowData) {
				let data = {
					pktask: this.pkTaskStr,
					pkequ: rowData.pkequ
				}
				$axiosPost('bindEqu', data).then(res => {
					if (res.data.code === 'success') {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
						this.dialogBindEqu = false;
						this.getTableData();
					} else {
						this.$notify.error({
							title: '错误',
							message: res.data.msg
						});
					}
				})
			},
			//将选中行的主键拼接成以‘，’隔开的方法
			splicingPk(ButtonState, editStates) {
				//按钮状态,修改状态【修改与双击】
				let checkData = this.multipleSelection; //获取到选中行的值
				let pkBasicUserThis = ''; //以‘，’分开的主键
				if (this.checkIndex === 0) {
					this.$message({
						message: '请选择数据进行此操作！',
						type: 'warning'
					});
					return false;
				}
				/* if (ButtonState === 100) {
				  //修改状态
				  if (this.checkIndex != 1) {
				    this.$message.warning('仅允许对一条数据进行查看！');
				    return false;
				  }
				} */
				for (var i = 0; i < checkData.length; i++) {
					/* if (ButtonState !== 3 && ButtonState !== 2 && editStates != 'DbClick' && editStates != 'enableEdit') {
					  //封存状态
					  if (checkData[i].states !== 1) {
					    this.$message.warning('只有自由状态才可执行此操作！');
					    return false;
					  }
					} */
					pkBasicUserThis += checkData[i].pktask + ',';
				}
				pkBasicUserThis = pkBasicUserThis.substring(0, pkBasicUserThis.lastIndexOf(','));
				this.pkTaskStr = pkBasicUserThis;
				return true;
			},
			//获取当前选中行数据方法
			handleSelectionChange(val) {
				this.multipleSelection = val;
				this.checkIndex = val.length;
			},
			//分页相关【下一页】操作
			handleSizeChangeFun(v) {
				this.pageSize = v;
				this.getTableData(); //更新列表
			},
			//分页相关【一页几条】操作
			handleCurrentChangeFun(v) {
				this.currentPage = v; //当前页
				this.getTableData(); //更新列表
			},

		}
	};
</script>
<style>

</style>